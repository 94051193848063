<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/case_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">经典案例</p>
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h95"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabs"> </Tab>
        <div class="case-title">传世助力人民同泰，TMS系统缩短车辆调度时长</div>
        <img class="w100f" src="../../assets/images/news_block2.png" alt="" />
        <div class="case-title">客户简介</div>
        <p>
          传世帮助建立哈药人民同泰商业物流运输管理体系，利用TMS系统优化现有工作模式，从而缩短车辆调度时长，提高运输配送效率。
        </p>
        <div class="h24"></div>
        <div class="case-img-box">
          <img class="w70f" src="../../assets/images/case_yly2.png" alt="" />
          <img class="w29f" src="../../assets/images/case_yly3.png" alt="" />
        </div>
        <div class="case-title">项目介绍</div>
        <p>
          利用TMS系统减少排车等待时间，避免调度与波次系统工作冲突；将系统内问题地址信息进行地址清洗，降低问题地址在调度过程中错误筛选；调度员对线路预维护，根据配送实际情况进行线路划分，定期做好数据分析，优化线路资源城配调度参数灵活度高，订单、家数、件数、体积等汇总情况一目了然；通过TMS系统升级，优化查单操作，降低人工操作时间。
        </p>
        <p>路线优化后，司机送货高效有序。缩短调度排车时间，由原来的额215分钟缩短至150分钟，哈药物流运输部整体工作效率得到提升。工作管理更高效化，全流程信息化可视化，企业精细化管理水平得到了大幅的提升，为哈药物流供应链效率的提升做出突出贡献。</p>
        <div class="h95"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>

<script>
import mobilePage from '@/views/case/mobile/case_yly.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Tab,
    Title,
    mobilePage
  },
  data() {
    return {
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs.tabs.currentIndex = 3
    })
  },
  methods: {
    onTabChange(index) {
      this.currentPageIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 42px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .case-title {
      font-size: 32px;
      text-align: left;
      margin: 43px 0;
    }
    p{
      font-size: 16px;
      text-align: left;
      text-indent: 2em;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .case-img-box{
      display: flex;
      justify-content: space-between;
    }
    .serve-img{
      width: 70%;
      margin-left: -30%;
    }
  }
}
</style>
